import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';


export default function SearchHelpCenterGuide() {
  return (
    <Layout
      keywords="Zendesk help center, integrate search AI"
      title="Set up intelligent search on your Zendesk help center page"
      description="Documentation on how to integrate IrisAgent search with your Zendesk help center page"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/search-help-center-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Documentation for IrisAgent Search on Zendesk Help Center</h2>
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Search on Zendesk Help Center</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>Integrating IrisAgent Search with your Zendesk Help Center</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent's AI can power search and Q&A engine on your Zendesk Help Center to deflect customer queries and reduce the number of tickets handled by support agents.
                    <br/>
                    <br/>
                    <h2>
                    Installation Guide
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    Navigate to the <b>Customize Design</b> tab in your Zendesk Help Center. This can be found under Zendesk Guide > Guide Admin > Customize Design.
                    </li>
                    <li>
                    Edit your theme. You can either add a new theme by selecting <b>Add theme</b> and making a copy of your Live theme by selecting the three dots > Copy.
If you would like to edit your existing theme directly, click on <b>Customize</b> under your Live theme.
<br/><img
                src="/img/zd-help-theme.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              /><br/>

                    </li>
                    <li>
                    In order to replace the default Zendesk search bar with the IrisAgent search bar, you will need to edit the code. After clicking on the <b>Customize</b> button in the previous step, Click <b>Edit Code</b> in the bottom right corner:
                    <br/><img
                src="/img/zd-help-edit-code.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              /><br/>

                    </li>
                    <li>
                    Navigate to the <b>Home Page</b>. Once in the editor, on the left hand side, click on the home page (home_page.hbs) file.
                    </li>
                    <li>
                    Within <b>home_page.hbs</b> file, add in the following lines underneath the h1 tag containing "help_center.name":
                    <SyntaxHighlighter language="javascript" style={prism}>
  {`

<script src="https://code.jquery.com/jquery-3.6.0.min.js"
       integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossOrigin="anonymous"></script>

<div
 id="res-div"
 style="position: absolute;
 top: 12%;
 left: 50%;
 transform: translateX(-50%);"
>
   <iframe
       src="https://app.irisagent.com/zendesk/zendesk-ticket-form-searchbar/getZendeskTicketFormSearchBar?businessId=REPLACE_ME_BUSINESS_ID"
       id="irisagent-ticket-form-search-iframe" frameBorder="0"
       style="width: 40em; min-width: 45%;">
 </iframe>
</div>

<script type="text/javascript">
  window.addEventListener('message', function (e) {
    if ( e.origin !== 'https://app.irisagent.com' ) {
      return;
    }
    if (e.data.startsWith("irisagent.resize")) {
        const splits = e.data.split('.');
        const size = splits[splits.length-1];
        $('#irisagent-ticket-form-search-iframe').height(\`\${size}px\`);
    }
  })
</script>
  `}
</SyntaxHighlighter>

                    </li>
                    <li>
                    Note: Insert your account business ID in the above code for <b>REPLACE_ME_BUSINESS_ID</b>. This is a secret that will be shared to you by IrisAgent.
                    </li>
                    <li>
                    Now that the IrisAgent search bar is going to be displayed in the home page, the default Zendesk search bar can be deleted from the code.
To make the deletion, remove the entire <code>{`<section id="main-content" class="section hero">`}</code> and all the code inside the section.
                    </li>
                    <li>
                    Also within home_page.hbs, replace the <code>{`<div class="container">`}</code> line with <code>{`<div class="container" style="margin-top: 10em;">`}</code>
                    </li>
                    <li>
                    Save your work. Click Save.
                    </li>
                    <li>
                    [optional] You can click on the preview button on the left side of the editor to see how the layout looks before deploying it.
                    </li>
                    <li>
                    That’s it, done! You should be seeing the IrisAgent search bar on your help center home page.
                    </li>
                    </ol>





                    </div>
                    </div>
                    </section>

</Layout>
  )
}
